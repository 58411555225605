import { useContext } from 'react';
import { PlaylistSettingsContext } from '../../providers/playlistSettings';
import { ShellContext } from '../../providers/shell';
import Temperature from './_titleBar/_Temperature';
import DateTime from './_titleBar/_DateTime';
import NewsTicker from './_titleBar/_NewsTicker';
import { shadeColor } from '../../tools';

export default function TitleBar() {
  const { titleBar: { position, color, feed, timestamp, weather } } = useContext(PlaylistSettingsContext);
  const { showTitleBar } = useContext(ShellContext);


  return (
    <div
      style={{
        width: '100%',
        height: '8vh',
        display: 'flex',
        visibility: showTitleBar ? 'visible' : 'hidden',
        justifyContent: 'space-between',
        backgroundColor: color,
        order: position === 'top' ? -1 : undefined,
      }}
    >
      <div style={{ flexGrow: 1 }}>
        {feed?.titles ? <NewsTicker /> : <div></div>}
      </div>

      {/** The date/time and temperature */}
      {timestamp?.showTime || timestamp?.showDate || weather ? (
        <div
        style={{
          marginLeft: '10px',
          paddingLeft: '1vmin',
          paddingRight: '1vmin',
          display: 'flex',
          gap: "2vmin",
          backgroundColor: shadeColor(color, -20),
        }}
      >
        {/** Date and time */}
        {timestamp?.showTime || timestamp?.showDate ? <DateTime /> : <div> </div>}

        {/** Temperature */}
        {weather ? <Temperature /> : <div> </div>}
      </div>
      ) : null}
    </div>
  );
}
